.backdrop
{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 200%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.473);
    z-index: 3;
}

.modal
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: white;
    border-radius: 5px;
    width: 80vw;
    padding: 20px;
    z-index: 4;
}

.modal a
{
  background-color: var(--gblue);
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
  margin: 20px 10px 5px;
  color: white;
}

.modal a:hover
{
  background-color: #326dee;
}
.modal span
{
    margin: 0px 5px;
    text-align: center;
}