.leaderboard
{
	margin: 10px auto 0px;
	width: 90%;
	padding: 20px 30px 30px;
	background-color: rgba(243, 242, 242, 0.918);
  color: black;
  font-size: 1.5rem;
}
.tableWrapper
{
  width: 100%;
  height: 80vh;
  overflow: scroll;
}

#leaderboard-table {
  border-collapse: collapse;
  width: 100%;
  }
  
  #leaderboard-table td, #leaderboard-table th {
	border: 2px dashed rgb(242, 191, 66);
	padding: 8px;

  }
  
  #leaderboard-table tr:nth-child(even){background-color: rgba(242, 191, 66, 0.363);}
  
  #leaderboard-table tr:hover {background-color: rgba(89, 166, 92,0.3);}
  
  #leaderboard-table th {
	font-size: 1.5rem;
	padding-top: 12px;
	padding-bottom: 12px;
	text-align: left;
	background-color: rgb(89, 166, 92);
	border: 3px solid rgb(238, 236, 236);
	color: white;
  }

  thead
  {
    position: sticky;
    top: 0;
  }
 
.crown
{
  height: 20px;
  margin: 0 10px;
}
  
.name
{
    display: flex;
    width: 100%;
    margin: 0;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.name span{
    width: 100%;
}
.linechart
{
    width: 90%;
    margin: 20px auto 20px;
}

.pfp
{
    width: 40px;
    border-radius: 40px;
    margin: 0 10px;
}

@media (max-width: 600px)
{
  .leaderboard
  {
    width: 85%;
  }
}

@media screen and (max-width: 590px){
  .pfp{
    width: 30px;
    
  }
  .crown{
    height: 10px;
    margin: 0 5px;
  }
  #leaderboard-table th{
    font-size: 1rem;
  }
  .leaderboard{
    font-size: 1rem;
  }
  .leaderboardTitle
  {
    font-size: 1.1rem;
  }
  .leaderboardTitle img
  {
    width: 80px;
  }
}
@media screen and (max-width: 435px){
  .pfp{
    width: 20px;
    
  }
  .crown{
    height: 8px;
    margin: 0 3px;
  }
  #leaderboard-table th{
    font-size: 0.7rem;
  }
  .leaderboard{
    font-size: 0.7rem;
  }
  .leaderboardTitle
  {
    font-size: 1rem;
  }
  .leaderboardTitle img
  {
    width: 60px;
  }

}
@media screen and (max-width: 355px){
  .pfp{
    width: 20px;
    
  }
  .crown{
    height: 7px;
    margin: 0 3px;
  }
  #leaderboard-table th{
    font-size: 0.5rem;
  }
  .leaderboard{
    font-size: 0.5rem;
  }
}