@import url('https://fonts.cdnfonts.com/css/product-sans');

:root
{
  --gblue:#5383ec;
  --gred:#d85140;
  --gyellow:#f2bf42;
  --ggreen:#59a65c;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Product Sans', sans-serif;
  color: rgb(110, 110, 110);
  max-width: 100vw !important;
  overflow-x: hidden;
                                                
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a
{
  text-decoration: none;
  color: black;
}

.header
{
  background-image: url("./assets/media/background-illustration.svg");
  width: calc(100vw - 60px);
  max-width: 100vw;
  overflow-x: hidden;
  padding: 30px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}



.extra 
{
  position: absolute;
  width: 150px;
  bottom: 70px;
  right: 100px;
}

.title
{
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 30px 0;
}
.title h2
{
  margin: 0;
}
.title h1
{
  margin-bottom: 10px;
}
.title img
{
  width: 150px;
  max-width: 80%;
}

.leaderboardTitle
{
  background-image: url("./assets/media/grid-check.png");
  background-clip: padding-box;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  text-align: center;
  padding: 20px;
  font-size: 1.3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: 300;

}

.leaderboardTitle h1
{
  background: white;
  padding: 5px 0;
}
.leaderboardTitle img
{
  width: 100px;
  margin: 0 10px;
}

.downloadLink
{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px 20px;
  margin: 20px 0;
  font-size: 1.2rem;
  background-color: var(--gblue);
  color: white;

}
.downloadLink span
{
  margin: 0 5px;
  vertical-align: middle;
  text-align: center;
}
.icon
{
  margin:auto 5px;
  height: 1em;
  position: relative;
  top: 2px;
}
.downloadLink a
{
  background-color: white;
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
  margin: 0 10px;
}

.downloadLink a:hover
{
  background-color: rgb(235, 235, 235);
}

footer
{
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("./assets/media/grid-check.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
footer small
{
  background: white;
}

.footer
{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.footer img
{
  margin: 0 20px;
  width: 80px;
}

@media (max-width:1000px)
{
  .extra
  {
    bottom: 50px;
    right: 50px;
    width: 100px;
  }
}
@media (max-width:842px)
{
  .footer img 
  {
    width: 70px;
  }
}

@media (max-width:750px)
{
  .extra
  {
    bottom: 50px;
    right: -20px;
    width: 100px;
  }
}

@media (max-width:650px)
{
  .extra
  {
    bottom: 50px;
    right: -40px;
    width: 100px;
  }
  .footer img 
  {
    width: 60px;
  }
}

@media (max-width:550px)
{
  .extra
  {
    display: none;
  }
  .gdsc
  {
    align-self: center !important;
  }
  .cloud
  {
    width: 100px !important;
  }
  .downloadLink
  {
    font-size: 0.9rem;
  }
  .footer h2 
  {
    font-size: 1rem;
  }
  .footer img 
  {
    width: 50px;
    margin: 0px 10px;
  }
  footer small 
  {
    font-size: 0.7rem;
  }
}

@media screen and (max-width: 355px)
{
  .title h1 
  {
    font-size: 1.7rem;
  }
  .title h2 
  {
    font-size: 1.3rem;
  }
  .cloud
  {
    width: 80px !important;
  }
}