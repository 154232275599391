@import url(https://fonts.cdnfonts.com/css/product-sans);
:root
{
  --gblue:#5383ec;
  --gred:#d85140;
  --gyellow:#f2bf42;
  --ggreen:#59a65c;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Product Sans', sans-serif;
  color: rgb(110, 110, 110);
  max-width: 100vw !important;
  overflow-x: hidden;
                                                
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a
{
  text-decoration: none;
  color: black;
}

.header
{
  background-image: url(/static/media/background-illustration.4a9e1ca2.svg);
  width: calc(100vw - 60px);
  max-width: 100vw;
  overflow-x: hidden;
  padding: 30px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}



.extra 
{
  position: absolute;
  width: 150px;
  bottom: 70px;
  right: 100px;
}

.title
{
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 30px 0;
}
.title h2
{
  margin: 0;
}
.title h1
{
  margin-bottom: 10px;
}
.title img
{
  width: 150px;
  max-width: 80%;
}

.leaderboardTitle
{
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABN4AAAC+BAMAAAAYZN9cAAAAHlBMVEX////////6+vrr6+z19fbw8PHu7u7v7/Ds7O3z8/ONxqQ7AAAAAXRSTlMAQObYZgAAAixJREFUeAHt2LVBbFEYhdGDhU9GYrwBxid6gkc45EgFlEAJNEaONoCUQAc7wS9rpRv5g290pCQjsz/v0j46dZvmsZmr+Ndnbkry/+EizfXPeJvbrp/iXy+vC75vb+gNvYHe0BvoDb2B3tAbegO9oTfQG3oDvaE39AZ6Q2+gN/SG3kBv6A30ht5Ab+gNvYHe0BvoDb2hN9AbegO9oTfQG3pDb6A39AZ6Q2/oDfSG3kBv6A30ht7QG+gNvYHe0BuM/7sorwdGap/m+Q1O9pLTbpx3+nHeH8T5oL0XnVXuNreV9vuBsruUnHfivDiM83Ivzuvtpeiocre5rfyrJXMLcW604tzsxrnerkU7lbvNbT4v4Ps39AZ6Q2+gN/SG3kBv6A30ht5Ab+gNvYHe0NuXhN7QG+gNvYHe0Bt6A72hN9AbegO9oTf0BnpDb6A39IbeQG/oDfSG3kBv6A29gd7QG+gNvaE30Bt6A72hN9AbekNvoDf0BnpDb7C7lJx34rw4jPNyL87r7aXoqHK3ua204f2MtmaTv6Nxnh6P89xEnOfLbLRZudvcVv7VkrmFODdacW5241xv16Kdyt3mttECPp+iN9AbegO9oTf0BnpDb6A39AZ6Q2/oDfSG3kBv6A29gd7QG+gNvYHe0Bt6A72hN9AbekNvoDf0BnpDb6A39IbeQG/oDfSG3tAb6A29gd7QG+gNvaE30Bt6A72hN4i9gd7QG+gNvUEb3s979gbPha7SQN6Qu5gAAAAASUVORK5CYII=);
  background-clip: padding-box;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  text-align: center;
  padding: 20px;
  font-size: 1.3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: 300;

}

.leaderboardTitle h1
{
  background: white;
  padding: 5px 0;
}
.leaderboardTitle img
{
  width: 100px;
  margin: 0 10px;
}

.downloadLink
{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px 20px;
  margin: 20px 0;
  font-size: 1.2rem;
  background-color: #5383ec;
  background-color: var(--gblue);
  color: white;

}
.downloadLink span
{
  margin: 0 5px;
  vertical-align: middle;
  text-align: center;
}
.icon
{
  margin:auto 5px;
  height: 1em;
  position: relative;
  top: 2px;
}
.downloadLink a
{
  background-color: white;
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
  margin: 0 10px;
}

.downloadLink a:hover
{
  background-color: rgb(235, 235, 235);
}

footer
{
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABN4AAAC+BAMAAAAYZN9cAAAAHlBMVEX////////6+vrr6+z19fbw8PHu7u7v7/Ds7O3z8/ONxqQ7AAAAAXRSTlMAQObYZgAAAixJREFUeAHt2LVBbFEYhdGDhU9GYrwBxid6gkc45EgFlEAJNEaONoCUQAc7wS9rpRv5g290pCQjsz/v0j46dZvmsZmr+Ndnbkry/+EizfXPeJvbrp/iXy+vC75vb+gNvYHe0BvoDb2B3tAbegO9oTfQG3oDvaE39AZ6Q2+gN/SG3kBv6A30ht5Ab+gNvYHe0BvoDb2hN9AbegO9oTfQG3pDb6A39AZ6Q2/oDfSG3kBv6A30ht7QG+gNvYHe0BuM/7sorwdGap/m+Q1O9pLTbpx3+nHeH8T5oL0XnVXuNreV9vuBsruUnHfivDiM83Ivzuvtpeiocre5rfyrJXMLcW604tzsxrnerkU7lbvNbT4v4Ps39AZ6Q2+gN/SG3kBv6A30ht5Ab+gNvYHe0NuXhN7QG+gNvYHe0Bt6A72hN9AbegO9oTf0BnpDb6A39IbeQG/oDfSG3kBv6A29gd7QG+gNvaE30Bt6A72hN9AbekNvoDf0BnpDb7C7lJx34rw4jPNyL87r7aXoqHK3ua204f2MtmaTv6Nxnh6P89xEnOfLbLRZudvcVv7VkrmFODdacW5241xv16Kdyt3mttECPp+iN9AbegO9oTf0BnpDb6A39AZ6Q2/oDfSG3kBv6A29gd7QG+gNvYHe0Bt6A72hN9AbekNvoDf0BnpDb6A39IbeQG/oDfSG3tAb6A29gd7QG+gNvaE30Bt6A72hN4i9gd7QG+gNvUEb3s979gbPha7SQN6Qu5gAAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
footer small
{
  background: white;
}

.footer
{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.footer img
{
  margin: 0 20px;
  width: 80px;
}

@media (max-width:1000px)
{
  .extra
  {
    bottom: 50px;
    right: 50px;
    width: 100px;
  }
}
@media (max-width:842px)
{
  .footer img 
  {
    width: 70px;
  }
}

@media (max-width:750px)
{
  .extra
  {
    bottom: 50px;
    right: -20px;
    width: 100px;
  }
}

@media (max-width:650px)
{
  .extra
  {
    bottom: 50px;
    right: -40px;
    width: 100px;
  }
  .footer img 
  {
    width: 60px;
  }
}

@media (max-width:550px)
{
  .extra
  {
    display: none;
  }
  .gdsc
  {
    align-self: center !important;
  }
  .cloud
  {
    width: 100px !important;
  }
  .downloadLink
  {
    font-size: 0.9rem;
  }
  .footer h2 
  {
    font-size: 1rem;
  }
  .footer img 
  {
    width: 50px;
    margin: 0px 10px;
  }
  footer small 
  {
    font-size: 0.7rem;
  }
}

@media screen and (max-width: 355px)
{
  .title h1 
  {
    font-size: 1.7rem;
  }
  .title h2 
  {
    font-size: 1.3rem;
  }
  .cloud
  {
    width: 80px !important;
  }
}
.leaderboard
{
	margin: 10px auto 0px;
	width: 90%;
	padding: 20px 30px 30px;
	background-color: rgba(243, 242, 242, 0.918);
  color: black;
  font-size: 1.5rem;
}
.tableWrapper
{
  width: 100%;
  height: 80vh;
  overflow: scroll;
}

#leaderboard-table {
  border-collapse: collapse;
  width: 100%;
  }
  
  #leaderboard-table td, #leaderboard-table th {
	border: 2px dashed rgb(242, 191, 66);
	padding: 8px;

  }
  
  #leaderboard-table tr:nth-child(even){background-color: rgba(242, 191, 66, 0.363);}
  
  #leaderboard-table tr:hover {background-color: rgba(89, 166, 92,0.3);}
  
  #leaderboard-table th {
	font-size: 1.5rem;
	padding-top: 12px;
	padding-bottom: 12px;
	text-align: left;
	background-color: rgb(89, 166, 92);
	border: 3px solid rgb(238, 236, 236);
	color: white;
  }

  thead
  {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }
 
.crown
{
  height: 20px;
  margin: 0 10px;
}
  
.name
{
    display: flex;
    width: 100%;
    margin: 0;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.name span{
    width: 100%;
}
.linechart
{
    width: 90%;
    margin: 20px auto 20px;
}

.pfp
{
    width: 40px;
    border-radius: 40px;
    margin: 0 10px;
}

@media (max-width: 600px)
{
  .leaderboard
  {
    width: 85%;
  }
}

@media screen and (max-width: 590px){
  .pfp{
    width: 30px;
    
  }
  .crown{
    height: 10px;
    margin: 0 5px;
  }
  #leaderboard-table th{
    font-size: 1rem;
  }
  .leaderboard{
    font-size: 1rem;
  }
  .leaderboardTitle
  {
    font-size: 1.1rem;
  }
  .leaderboardTitle img
  {
    width: 80px;
  }
}
@media screen and (max-width: 435px){
  .pfp{
    width: 20px;
    
  }
  .crown{
    height: 8px;
    margin: 0 3px;
  }
  #leaderboard-table th{
    font-size: 0.7rem;
  }
  .leaderboard{
    font-size: 0.7rem;
  }
  .leaderboardTitle
  {
    font-size: 1rem;
  }
  .leaderboardTitle img
  {
    width: 60px;
  }

}
@media screen and (max-width: 355px){
  .pfp{
    width: 20px;
    
  }
  .crown{
    height: 7px;
    margin: 0 3px;
  }
  #leaderboard-table th{
    font-size: 0.5rem;
  }
  .leaderboard{
    font-size: 0.5rem;
  }
}
.searchBar
{
    width: 97%;
    padding: 10px 10px;
    margin: 20px auto 30px;
    background-color: rgba(216, 216, 216, 0.788);
    border-radius: 10px;
    align-self: center;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.search-icon
{
    align-self: center;
    justify-self: flex-start;
    margin: 5px 10px;
    height: 100%;
    fill: rgb(88, 88, 88);
}

.searchinput
{
    background-color: transparent;
    border: none;
    width: 100%;
    margin: 5px 10px;
    margin-left: 5px;
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    color: black;
}

.searchinput::-webkit-input-placeholder
{
    font-size: 1rem;
    color: rgba(27, 27, 27, 0.644);
}

.searchinput:-ms-input-placeholder
{
    font-size: 1rem;
    color: rgba(27, 27, 27, 0.644);
}

.searchinput::placeholder
{
    font-size: 1rem;
    color: rgba(27, 27, 27, 0.644);
}

.searchinput:focus
{
    outline: none;
}

  .loader
  {
      display: flex;
      width: 100%;
      justify-content: center;
      flex-direction: row;
      margin: 30px auto;
  }

   .dot-flashing {
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: #d85140;
    color: #d85140;
    -webkit-animation: dot2Flashing 1s infinite linear alternate;
            animation: dot2Flashing 1s infinite linear alternate;
    -webkit-animation-delay: .5s;
            animation-delay: .5s;
  }
  
  .dot-flashing::before, .dot-flashing::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }
  
  .dot-flashing::before {
    left: -30px;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: #f2bf42;
    color: #f2bf42;
    -webkit-animation: dot1Flashing 1s infinite alternate;
            animation: dot1Flashing 1s infinite alternate;
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
  }
  
  .dot-flashing::after {
    left: 30px;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: #5383ec;
    color: #5383ec;
    -webkit-animation: dot3Flashing 1s infinite alternate;
            animation: dot3Flashing 1s infinite alternate;
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
  }
  
  @-webkit-keyframes dot1Flashing {
    0% {
      background-color: #eee3cb;
    }
    50%,
    100% {
      background-color: #f2bf42;
    }
  }
  
  @keyframes dot1Flashing {
    0% {
      background-color: #eee3cb;
    }
    50%,
    100% {
      background-color: #f2bf42;
    }
  }
  @-webkit-keyframes dot2Flashing {
    0% {
      background-color: #e4bab5;
    }
    50%,
    100% {
      background-color: #d85140;
    }
  }
  @keyframes dot2Flashing {
    0% {
      background-color: #e4bab5;
    }
    50%,
    100% {
      background-color: #d85140;
    }
  }
  @-webkit-keyframes dot3Flashing {
    0% {
      background-color: #bdcdee;
    }
    50%,
    100% {
      background-color: #5383ec;
    }
  }
  @keyframes dot3Flashing {
    0% {
      background-color: #bdcdee;
    }
    50%,
    100% {
      background-color: #5383ec;
    }
  }
.backdrop
{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 200%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.473);
    z-index: 3;
}

.modal
{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    background-color: white;
    border-radius: 5px;
    width: 80vw;
    padding: 20px;
    z-index: 4;
}

.modal a
{
  background-color: var(--gblue);
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
  margin: 20px 10px 5px;
  color: white;
}

.modal a:hover
{
  background-color: #326dee;
}
.modal span
{
    margin: 0px 5px;
    text-align: center;
}
